// extracted by mini-css-extract-plugin
export var resumeSection = "Resume-module--resumeSection--toEG3";
export var resumeBar = "Resume-module--resumeBar--1V_wM";
export var tapForDetails = "Resume-module--tapForDetails--ssU1i";
export var cdTimeline = "Resume-module--cdTimeline--Q81h2";
export var cdTimelineContainer = "Resume-module--cdTimelineContainer--378NS";
export var cdTimelineBlock = "Resume-module--cdTimelineBlock--3CU9y";
export var cdTimelineImg = "Resume-module--cdTimelineImg--1bOZj";
export var cdTimelineImgPicture = "Resume-module--cdTimelineImgPicture--1Sb_9";
export var cdTimelineContent = "Resume-module--cdTimelineContent--VQDPD";
export var jobTopContainer = "Resume-module--jobTopContainer--3rb4B";
export var cdTimelineReadMore = "Resume-module--cdTimelineReadMore--2HeH0";
export var cdTimelineDate = "Resume-module--cdTimelineDate--1jEyG";
export var cdIsHidden = "Resume-module--cdIsHidden--3EZDV";
export var cdTimelineImgBounceIn = "Resume-module--cdTimelineImg--bounce-in--AMLLp";
export var cdBounce1 = "Resume-module--cd-bounce-1--1OQ4I";
export var wrapCollabsible = "Resume-module--wrapCollabsible--1mUyQ";
export var lblToggle = "Resume-module--lblToggle--1pRlW";
export var toggle = "Resume-module--toggle--24-QH";
export var collapsibleContent = "Resume-module--collapsibleContent--2Jm2V";
export var contentInner = "Resume-module--contentInner--1rFE-";