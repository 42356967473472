// extracted by mini-css-extract-plugin
export var projectsSection = "Projects-module--projectsSection--rrnqE";
export var projectsContainer = "Projects-module--projectsContainer--PqPxF";
export var projectsBar = "Projects-module--projectsBar--2gHkv";
export var projectHR = "Projects-module--projectHR--2XgDz";
export var project = "Projects-module--project--2dF5T";
export var description = "Projects-module--description--3MWNy";
export var nameAndLinks = "Projects-module--nameAndLinks--1AeeW";
export var linksContainer = "Projects-module--linksContainer--Z6E7Q";
export var projectname = "Projects-module--projectname--1DpX9";
export var excerpt = "Projects-module--excerpt--1Ro59";
export var mockupAndLinks = "Projects-module--mockupAndLinks--3o07I";
export var mockupContainer = "Projects-module--mockupContainer--3Epyz";
export var mockupOverlay = "Projects-module--mockupOverlay--17RIB";
export var mockup = "Projects-module--mockup--MS3vz";
export var techStack = "Projects-module--techStack--oa720";