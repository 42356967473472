import React from 'react'
import * as styles from './Resume.module.css'
import { scrollToTop } from '../utils'

import {
  clicktripzImg,
  porterandsailImg,
  fsaImg,
  mizuhoImg,
  lordabbettImg,
  nyuImg,
  browserImg,
  bankImg,
  fundImg,
  diplomaImg
} from '../images/resume'

import { chevronWhite } from '../images'

const ResumeItem = (props) => {
  return (
    <div className={`${styles.cdTimelineBlock} jsCdBlock`}>
      <div
        className={`${styles.cdTimelineImg} ${styles.cdTimelineImgPicture} jsCdImg`}
      >
        <img src={props.timelineIcon} alt='milestone marker' />
      </div>

      <div className={`${styles.cdTimelineContent} jsCdContent`}>
        <div className={styles.wrapCollabsible}>
          <input id={props.id} className={styles.toggle} type='checkbox' />

          <label htmlFor={props.id} className={styles.lblToggle}>
            <div className={styles.jobTopContainer}>
              <h4>{props.jobTitle}</h4>
              <img alt={`${props.companyName} logo`} src={props.logo} />
            </div>

            <h5>{props.department}</h5>
            <h5>{props.group}</h5>
          </label>

          <div className={styles.collapsibleContent}>
            <div className={styles.contentInner}>
              <ul>
                {props.descriptions &&
                  props.descriptions.map((description, i) => (
                    <li key={i}>{description}</li>
                  ))}
              </ul>
            </div>
          </div>

          <span className={styles.cdTimelineDate}>{props.dates}</span>
        </div>
      </div>
    </div>
  )
};

const Resume = () => (
  <div id='resume' className={styles.resumeSection}>
    <div className={styles.resumeBar}>
      <h1>Experience</h1>
      <button onClick={() => scrollToTop()}>
        <img alt='arrow up' src={chevronWhite} />
      </button>
    </div>
    <section className={`${styles.cdTimeline} jsCdTimeline`}>
      <div className={styles.tapForDetails}>
        <span>click card for details</span>
      </div>
      <div className={styles.cdTimelineContainer}>
      <ResumeItem
          id='ClicktripzSWE'
          companyName='Clicktripz'
          jobTitle='Software Engineer'
          department=''
          group=''
          dates='Jun 2021 - Present'
          descriptions={[
            'Building innovative software solutions to serve the needs of suppliers, publishers, advertisers and travelers'
          ]}
          siteUrl='https://www.clicktripz.com/'
          logo={clicktripzImg}
          timelineIcon={browserImg}
        />

        <ResumeItem
          id='FullstackSrInstructor'
          companyName='Fullstack Academy'
          jobTitle='Senior Instructor'
          department=''
          group='Software Engineering Immersive'
          dates='Apr 2021 - May 2021'
          descriptions={[
            `Everybody's favorite instructor`
          ]}
          siteUrl='https://www.fullstackacademy.com/'
          logo={fsaImg}
          timelineIcon={browserImg}
        />

        <ResumeItem
          id='FullstackInstructor'
          companyName='Fullstack Academy'
          jobTitle='Instructor'
          department=''
          group='Software Engineering Immersive'
          dates='Sep 2019 - Mar 2021'
          descriptions={[
            `Taught all 54 unique academic lectures to cohorts upwards of 50 students – topics include vanilla JavaScript, Node, Express, RESTful API, RDBMSs (PostgreSQL), ORMs, React, Redux, Socket.IO, data structures, algorithm analysis, and authentication`,
            `Provided project management and code reviews on student projects by making sure they were on schedule and produced following industry best practices`,
            `Helped iterate on full-stack curriculum based on student achievement data, core business needs, and the evolving trends of the software engineer industry, including the migration of codebases to the latest ECMAScript version`,
            `Created a comprehensive proposal template and quality assurance protocol to introduce structure and process to the Instructional Team`
          ]}
          siteUrl='https://www.fullstackacademy.com/'
          logo={fsaImg}
          timelineIcon={browserImg}
        />

        <ResumeItem
          id='PorterAndSail'
          companyName='Porter & Sail'
          jobTitle='Software Engineer'
          department=''
          group=''
          dates='Aug 2018 - Aug 2019'
          descriptions={[
            `Built APIs using Ruby on Rails that handle complex data models efficiently`,
            `Worked closely with the CTO and Director of Product to conceptualize and develop features from a redesigned ”discovery feed” to new hotel guest pre-arrival flow`,
            `Led integration of React into the web application`,
            `Took complete ownership of high-trafficked features including the pre-arrival guest flow`,
            `Integrated third-party APIs`,
            `Actively recommended and implemented enhancement tools to streamline processes such as client contract automation and scheduled job to flag permanently closed venues`,
            `Heavily participated in UX and UI conversations`
          ]}
          siteUrl='https://www.porterandsail.com/'
          logo={porterandsailImg}
          timelineIcon={browserImg}
        />

        <ResumeItem
          id='FullstackTF'
          companyName='Fullstack Academy'
          jobTitle='Software Engineering Teaching Fellow'
          department='NYC Web Development Fellowship'
          group='Software Engineering Immersive'
          dates='Apr 2018 - Jul 2018'
          descriptions={[
            'Mentored 35 students on development concepts, algorithms, debugging strategies, software architecture and clean code structure',
            'Conducted technical interviews for applicants',
            'Led interactive reviews for students to solidify material ',
            'Directed team building exercises to strengthen class cohesion and community'
          ]}
          siteUrl='https://www.fullstackacademy.com/'
          logo={fsaImg}
          timelineIcon={browserImg}
        />

        <ResumeItem
          id='MizuhoRM'
          companyName='Mizuho Bank, Ltd'
          jobTitle='Risk Management Officer'
          department='Risk Management Department'
          group='Portfolio Analytics & Modeling'
          dates='Apr 2017 - Jan 2018'
          descriptions={[
            'Built and maintained an automated, comprehensive daily risk management dashboard for C-Suite executives that utilizes and manipulates data fed from several files, Bloomberg API, and Microsoft SQL database tables',
            'Authored monthly cross-asset market and macroeconomic recaps for senior management risk committee meetings',
            'Performed data cleansing on large data sets in order to identify and correct erroneous records'
          ]}
          siteUrl='https://map-it-fsa.firebaseapp.com/'
          logo={mizuhoImg}
          timelineIcon={bankImg}
        />

        <ResumeItem
          id='MizuhoPMDS'
          companyName='Mizuho Bank, Ltd'
          jobTitle='Portfolio Management Officer'
          department='Portfolio Management Department'
          group='Strategic Credit Hedge'
          dates='Sep 2015 - Mar 2017'
          descriptions={[
            'Developed and automated portfolio analytics for the group’s $55.6 billion loan portfolio in order to determine potential trade ideas based on single-name CDS relative value',
            'Built an archive and tracking tool to review trade performance using Bloomberg API and VBA',
            'Created a post-trade evaluation template, which pulls in and manipulates data from the archive in order to identify and attribute performance across an array of metrics versus a relevant benchmark',
            'Tested portfolio management system patches by drawing up test scenarios, simulating dummy trades and debugging results with third party vendor, Murex, and internal technology personnel',
            'Created a tool to track research blog traffic and identify users using VBA and Python knowledge',
            'Generated a portfolio profit and loss simulation report to assess potential market scenarios',
            'Produced a number of comprehensive manuals outlining procedures and operations'
          ]}
          siteUrl='https://saucesome-fsa.herokuapp.com/'
          logo={mizuhoImg}
          timelineIcon={bankImg}
        />

        <ResumeItem
          id='LordAbbettAT'
          companyName='Lord, Abbett & Co LLC'
          jobTitle='Associate Trader'
          department='Fixed Income Mutual Funds'
          group='Municipal Bonds'
          dates='Oct 2013 - Sep 2015'
          descriptions={[
            'Developed and maintain a database of obligors by security in order to analyze differences in market value weighting and duration contribution between each fund and its internal benchmark',
            'Maintained a database of new issue pricing scales to determine the maximum returning maturity, and evaluate spreads versus other new issue deals and existing markets in the secondary',
            'Filtered sell-side prices, market information and research to identify relative value opportunities and relay information to portfolio managers with $10 billion in assets under management',
            'Analyzed performance attribution using Barclays POINT to distinguish deficiencies and strengths in fund asset allocation and security selection on a monthly, quarterly and yearly basis'
          ]}
          siteUrl='https://symbalplayer.firebaseapp.com/'
          logo={lordabbettImg}
          timelineIcon={fundImg}
        />

        <ResumeItem
          id='LordAbbettPA'
          companyName='Lord, Abbett & Co LLC'
          jobTitle='Portfolio Analyst'
          department='Fixed Income Mutual Funds'
          group='Municipal Bonds'
          dates='Jun 2011 - Sep 2013'
          descriptions={[
            'Constructed a database using VBA and Barclays POINT to present and maintain weekly year-to-date total return changes within the Barclays Aggregate and High Yield indices using several partitions',
            'Collaborated with the technology team to create a heat map that highlights fund versus internal index differences in yield curve, quality, time to next call, price, sector, and state positioning'
          ]}
          logo={lordabbettImg}
          timelineIcon={fundImg}
        />

        <ResumeItem
          id='NYU'
          companyName='New York University'
          jobTitle='Bachelor of Arts'
          department='Economics, College of Arts & Science'
          group='Business Studies, Stern School of Business '
          dates='May 2011'
          descriptions={['NYU Florence semester abroad']}
          logo={nyuImg}
          timelineIcon={diplomaImg}
        />
      </div>
    </section>
  </div>
);

export default Resume
